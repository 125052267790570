import { useEffect, useRef } from 'react'

import Intercom, { boot, shutdown } from '@intercom/messenger-js-sdk'
import type { IntercomSettings } from '@intercom/messenger-js-sdk/dist/types'

import { getIsAuthenticated, useAuthState } from 'hooks/useAuthState'
import { useEsConfig } from 'hooks/useEsConfig'
import { getSettings } from 'utils/settings'

// 76 from the bottom bar and 20 for the usual padding
const VERTICAL_PADDING = 96

/**
 * Initializes Intercom.
 *
 * Also adds in custom attributes that we can use to filter
 * and segment users in Intercom.
 */
export default function IntercomMessenger(): null {
  const hasBeenInitialized = useRef(false)
  const authState = useAuthState()
  const isAuthenticated = getIsAuthenticated(authState)
  const { getTranslation } = getSettings()
  const { data } = useEsConfig()

  useEffect(() => {
    if (!isAuthenticated || !import.meta.env.VITE_INTERCOM_APP_ID) {
      return () => {}
    }

    const { userAttributes, userMetadata } = authState

    const intercomSettings: IntercomSettings = {
      app_id: import.meta.env.VITE_INTERCOM_APP_ID,
      user_id: userMetadata?.payload.sub,
      email: userAttributes?.email,
      vertical_padding: VERTICAL_PADDING,

      // The following are custom attributes that we can use
      // to filter and segment users in Intercom
      pladia_event_name: getTranslation(data?.document.name),
      pladia_product_name: 'Event Sync',
      pladia_product_url: window.location.origin,
      pladia_customer_name: data?.customer.display_name,
    }

    if (!hasBeenInitialized.current) {
      // This needs to be called to initialize the Intercom chat,
      // when this is called, the intercom chat will be rendered
      // however, if intercom is shutdown and this is called again
      // the chat will not be rendered
      Intercom(intercomSettings)
      hasBeenInitialized.current = true
    } else {
      // We call boot if the Intercom chat has been initialized and
      // shutdown, as calling `Intercom` won't load the chat again
      // Calling boot without calling Intercom first will not
      // render the chat, even if we call the show function
      boot(intercomSettings)
    }

    return () => {
      shutdown()
    }
    // We don't need the other dependencies, just isAuthenticated
  }, [isAuthenticated, data, authState, getTranslation])

  return null
}
