import type { MutableRefObject, PropsWithChildren } from 'react'
import { createContext, useEffect, useRef, useState } from 'react'

import type { ChangeObject, StateObject, SystemObject } from './types'
import WebsocketService from './websocket-service'

import { useEsConfig } from 'hooks/useEsConfig'
import { logger } from 'utils/logger'

export type SyncServerContextType = {
  socket: MutableRefObject<WebsocketService>
  status: 'active' | 'error' | 'inactive' | 'connecting'
}

export const SyncServerContext = createContext<
  SyncServerContextType | undefined
>(undefined)

export const SocketContext = ({
  children,
}: PropsWithChildren): JSX.Element => {
  const [status, setStatus] =
    useState<SyncServerContextType['status']>('inactive')
  const { data } = useEsConfig()
  const socket = useRef<WebsocketService>(
    WebsocketService.instantiate(
      '',
      () => {},
      () => {},
      () => {},
    ),
  )
  useEffect(() => {
    if (!data) {
      return
    }

    const socketURL = new URL(data.sockets.public_url)
    socket.current = WebsocketService.instantiate(
      `${socketURL}`,
      (stateObject: StateObject) => {
        logger('state', stateObject)
        setStatus(stateObject.system.state)
      },
      (change: ChangeObject) => {
        logger('change', change)
      },
      (systemObject: SystemObject) => {
        logger('system', systemObject)
        setStatus(systemObject.state)
      },
    )

    // Connect to the sync server.
    socket.current.connect()
    return () => {
      if (socket.current) {
        socket.current.close()
      }
    }
  }, [data])
  return (
    <SyncServerContext.Provider value={{ socket, status }}>
      {children}
    </SyncServerContext.Provider>
  )
}
