import i18next from 'i18next'
import { createRoot } from 'react-dom/client'
// @ts-ignore - This is a valid import
import smartOutline from 'smart-outline'

import 'react-tooltip/dist/react-tooltip.css'
import './index.css'
import { App } from './App'
import { locales } from './locales'
import Settings from './settings'

import { i18n } from 'utils/i18n'
import { logger } from 'utils/logger'
import { mixpanelInit } from 'utils/mixpanel'
import { setupSettings } from 'utils/settings'

// Initialise settings from settings.ts
setupSettings(Settings)

// Initialise translations for static strings
i18n(locales, Settings.languages, Settings.defaultLanguage).then(() =>
  logger('Initial language is', i18next.resolvedLanguage),
)

// Initialize smart outline
smartOutline.init()

mixpanelInit({
  debug: import.meta.env.DEV,
})

// Mount router
const container = document.getElementById('root')
const root = createRoot(container!)
root.render(<App />)
